<template lang="pug">
div
  Dropdown#border(
    :label="$t('border')"
    layout="col"
    :items="borderItems"
    v-model="borderValue"
    :editMobile="editMobile"
  )
  MoreOrLess(hideToggler :open="isExpanded" :class="{ 'mt-3': isExpanded }")
    Dropdown#borderType(
      :label="$t('borderType')"
      layout="col"
      :items="borderTypeItems"
      v-model="borderTypeValue"
      :editMobile="editMobile"
    )
    OmColorInput(
      :label="$t('borderColor')"
      :property="coloringProperty"
      :typeOfComponent="typeOfComponent"
      layout="col"
      :editMobile="editMobile"
      dsAllowed
    )
    RangeInput(
      :label="$t('width')"
      :min="1"
      :max="200"
      :step="1"
      v-model="widthValue"
      :editMobile="editMobile"
    )
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Dropdown from '../Dropdown/Dropdown.vue';
  import MoreOrLess from '../MoreOrLess.vue';
  import ColorInput from '../ColorInput/ColorInput.vue';
  import RangeInput from '../RangeInput/RangeInput.vue';

  export default {
    components: {
      Dropdown,
      MoreOrLess,
      ColorInput,
      RangeInput,
    },
    mixins: [itemMixin],
    props: {
      typeOfComponent: {
        type: String,
        required: true,
      },
      selectedProperty: {
        type: String,
        default: 'border.selectedBorder',
      },
      typeProperty: {
        type: String,
        default: 'border.type',
      },
      widthProperty: {
        type: String,
        default: '$device.border.width',
      },
      coloringProperty: {
        type: String,
        default: '$device.border.color',
      },
      editMobile: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapState(['mobilePreview']),
      borderItems() {
        const baseBorderItems = [
          { value: 'no', text: this.$t('none') },
          { value: 'full', text: this.$t('full') },
          { value: 'top', text: this.$t('top') },
          { value: 'bottom', text: this.$t('bottom') },
          { value: 'left', text: this.$t('left') },
          { value: 'right', text: this.$t('right') },
          { value: 'top-bottom', text: this.$t('topBottom') },
        ];

        if (this.mobilePreview) {
          baseBorderItems.unshift({ value: null, text: this.$t('default') });
        }

        return baseBorderItems;
      },
      borderValue: {
        get() {
          return this.getValueOf(this.selectedProperty);
        },
        set(value) {
          this.setValueOf(this.selectedProperty, value);
        },
      },
      borderTypeItems() {
        const baseBorderTypeItems = [
          { value: 'solid', text: this.$t('solid') },
          { value: 'dashed', text: this.$t('dashed') },
          { value: 'dotted', text: this.$t('dotted') },
        ];
        if (this.mobilePreview) {
          baseBorderTypeItems.unshift({ key: null, text: this.$t('sameAsDesktop') });
        }

        return baseBorderTypeItems;
      },
      borderTypeValue: {
        get() {
          return this.getValueOf(this.typeProperty);
        },
        set(value) {
          this.setValueOf(this.typeProperty, value);
        },
      },
      widthValue: {
        get() {
          const mobileValue = this.getValueOf(this.widthProperty.replace('$device', 'mobile'));
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf(this.widthProperty.replace('$device', 'desktop')));
          }
          return Number(this.getValueOf(this.widthProperty));
        },
        set(value) {
          this.smartSetValueOf(this.widthProperty, value);
        },
      },
      isExpanded() {
        return !['no', null].includes(this.borderValue);
      },
    },
  };
</script>
