<template lang="pug">
CornerRadius(
  :label="isThemeKit ? $t('borderThemeKitRounding') : $t('borderRadius')"
  :needsToggle="isThemeKit"
  :cornerValues="cornerValues"
  :isChained="isChained"
  :isFallback="isFallback"
  :editMobile="editMobile"
  @input="onInput"
  @themeCorners="setThemeCorners"
  @setChained="setChained"
)
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import CornerRadius from '../CornerRadius/CornerRadius.vue';

  export default {
    components: {
      CornerRadius,
    },
    mixins: [itemMixin],
    props: {
      value: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
      },
    },
    data: () => ({
      isFallback: false,
    }),
    computed: {
      ...mapState(['selectedElement', 'mobilePreview', 'themeKit']),
      ...mapGetters(['isThemeKit']),
      cornerValues() {
        const cornerValues = this.getValueOf('border.radius');

        if (this.mobilePreview && cornerValues.every((value) => value === null)) {
          this.isFallback = true;
          return this.getValueOf('desktop.border.radius');
        }

        this.isFallback = false;
        return this.getValueOf('border.radius');
      },
      isChained: {
        get() {
          return this.getValueOf('border.radiusChained');
        },
        set(v) {
          this.setValueOf('border.radiusChained', v);
        },
      },
    },
    methods: {
      setChained(value) {
        this.isChained = value;
        if (value) {
          const itemValue = this.getValueOf('border.radius');

          let newItemValue = JSON.parse(JSON.stringify(itemValue));
          newItemValue = itemValue.map(() => itemValue[0]);
          this.setValueOf('border.radius', newItemValue);
        }
      },
      setThemeCorners(value) {
        this.setValueOf('border.customThemeRounding', value);
        const type = this.themeKit.rounding;

        if (!value) {
          this.updateElementStyle(this.selectedElement.uid);
          return;
        }

        this.$store.commit('updateCustomThemeRadius', { value: type });
      },
      onInput({ index, value }) {
        const itemValue = this.getValueOf('border.radius');

        let newItemValue = JSON.parse(JSON.stringify(itemValue));
        newItemValue[index] = value;

        if (this.isChained) {
          newItemValue = (newItemValue || []).map(() => value);
        }

        newItemValue = newItemValue.map((value) => Number(value));

        this.setValueOf('border.radius', newItemValue);
      },
    },
  };
</script>
