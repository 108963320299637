<template lang="pug">
.ds-range-input
  .ds-range-input-content
    PaneLayout(layout="col" gridLayout="two-third")
      template(#left)
        .ds-range-input-content-row.pr-2
          label.ds-range-input-label {{ label }}&nbsp;
            DeviceSelector(v-if="editMobile" :hasViewText="false")
          #ds-range-input-wrapper.mt-1
            .ds-range-input-low-filler(:style="{ width: calculatedWidth }")
            input#ds-range-input-slider(
              type="range"
              :min="min"
              :max="max"
              :step="step"
              v-model="valueMod"
            )
      template(#right)
        span.ds-range-input-value-indicator(
          :class="{ focus: focus, 'ds-range-input-value-indicator-invalid': isInvalid }"
        )
          input.ds-range-input-value-style(
            v-model="valueMod"
            @focus="focus = true"
            @keyup="modifyValue"
            @change="$emit('input', value)"
          )
          span.ds-range-input-unit(v-if="value !== null") {{ unit }}
</template>

<script>
  import { debounce } from 'lodash-es';
  import designSystemMixin from '@/components/Elements/mixins/designSystem';

  export default {
    name: 'RangeInput',
    components: {
      PaneLayout: () => import('../PaneLayout.vue'),
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
    },
    mixins: [designSystemMixin],
    props: {
      label: { type: String, required: true },
      min: { type: Number, default: 0 },
      max: { type: Number, default: 200 },
      step: { type: Number, default: 5 },
      value: { type: Number },
      unit: { type: String, default: 'px' },
      editMobile: { type: Boolean, default: false },
      isInvalid: { type: Boolean, default: false },
    },
    data() {
      return {
        focus: false,
      };
    },

    computed: {
      calculatedWidth() {
        const value = (this.valueMod / this.max) * 100;
        const percentage = value < 0 ? 0 : value > 100 ? 100 : value;
        return `${percentage}%`;
      },
      valueMod: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
    },
    methods: {
      modifyValue: debounce(function (event) {
        let value = this.valueMod;
        if (event.code === 'ArrowDown' && this.min < this.valueMod) {
          value--;
        } else if (event.code === 'ArrowUp' && this.valueMod < this.max) {
          value++;
        }
        this.$emit('input', value);
      }, 100),
    },
  };
</script>

<style lang="sass">
  @import 'rangeInput.sass'
</style>
