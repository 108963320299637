<template lang="pug">
InputGroupControl(:label="$t('margin')" type="margin")
</template>
<script>
  import InputGroupControl from './InputGroup.vue';

  export default {
    components: { InputGroupControl },
  };
</script>
